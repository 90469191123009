<template>
    <v-container class="w-container editPagamentoAlert" style="padding-top:59px">

        <v-row>


            <v-col class='mainCont' style="min-width:100vw;position:fixed;left:0;padding-left:0px;z-index:3">

                <div class="mainTitle" style="
            background-color: white;
            color: black;
            font-weight: bold;
            padding: 4px;
            font-size: 10px;
            min-width:100vw;
            border-color: #45b7e9">{{ titolo }}</div>

            </v-col>

        </v-row>

        <v-form style="" v-model="valid" ref="form" lazy-validation>



            <v-row>

                <v-col cols="12" md="12">



                    <v-textarea v-model="pagamento.descrizione" id="pagamento_descrizione" label="Nome / Descrizione"
                        :disabled="disField" maxlength="200"></v-textarea>

                </v-col>

            </v-row>

            <v-row v-if="viewRowAzienda">

                <v-col cols="12" md="12">
                    <div id="cont_companyid" style="cursor:pointer">
                        <ion-item
                            style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                            <ion-label style="font-size:12px;color:gray!important;opacity:1;cursor:pointer"
                                position="floating">Azienda </ion-label>

                            <ion-select id="companyid" name="companyid" class='companyid' cancel-Text="Chiudi"
                                done-Text="" :value="pagamento.companyid" interface="action-sheet" disabled readonly>

                                <ion-select-option v-for="item in aziende" :key="item.companyid"
                                    :value='item.companyid'> {{
                item.fld_name }}</ion-select-option>

                            </ion-select>
                        </ion-item>
                    </div>

                </v-col>

            </v-row>

            <v-row>

                <v-col cols="12" md="12">

                    <v-menu v-model="showPicker" :close-on-content-click="false" transitions="scale-transition" offset-y
                        max-width="290px" min-width="290px">
                        <template v-slot:activator="{ on }">
                            <v-text-field v-model="pagamento.data_scadenza" id="pagamento_data_scadenza"
                                label="Data Scadenza" persistent-hint readonly v-on="on" :disabled="disField">
                            </v-text-field>
                        </template>
                        <v-date-picker v-model="pagamento.data_scadenza" no-title @input="showPicker = false"
                            locale="it"></v-date-picker>
                    </v-menu>

                </v-col>

            </v-row>

            <v-row>

                <v-col cols="12" md="12">

                    <div id="cont_id_tipologia" style="cursor:pointer">

                        <ion-item id="item_id_tipologia" style="min-width:200px;transform: translateX(-6px);">
                            <ion-label style="font-size:12px;color:gray!important;opacity:1" position="floating">
                                Tipologia </ion-label>
                            <ion-select id="tipologia" name="tipologia" class='tipologia' cancel-Text="Chiudi"
                                done-Text="" :value="pagamento.id_tipologia" interface="action-sheet" disabled readonly>
                                <ion-select-option v-for="item in tipologie" :key="item.id" :value='item.id'> {{
                item.nome
            }}</ion-select-option>
                            </ion-select>
                        </ion-item>

                    </div>

                </v-col>

            </v-row>

            <v-row>


                <v-col cols="12" md="12">

                    <!-- <v-text-field v-model="pagamento.importo" label="Da Pagare (€)" id="d_importo"></v-text-field> -->


                    <!-- <div id="d_importo" style="cursor:pointer!important;z-index:999999999999999!important"
                        class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                        <div class="v-input__control">
                            <div class="v-input__slot">
                                <div class="v-text-field__slot"><label for="input-29"
                                        class="v-label v-label--active theme--light"
                                        style="left: 0px; right: auto; position: absolute">Da Pagare</label>

                                    <vue-autonumeric v-model="pagamento.importo" :disabled="true" :options="{
                digitGroupSeparator: '.',
                decimalCharacter: ',',
                decimalCharacterAlternative: '.',
                currencySymbol: '\u00a0€',
                currencySymbolPlacement: 's',
                roundingMethod: 'U',
                minimumValue: '0'
            }"></vue-autonumeric>

                                </div>
                            </div>

                            <div class="v-text-field__details">
                                <div class="v-messages theme--light">
                                    <div class="v-messages__wrapper"></div>
                                </div>
                            </div>

                        </div>
                    </div> -->


                    <div 
                        class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                        <div class="v-input__control">
                            <div class="v-input__slot">
                                <div class="v-text-field__slot"><label for="input-29"
                                        class="v-label v-label--active theme--light"
                                        style="left: 0px; right: auto; position: absolute">Da Pagare</label>

                                    <vue-autonumeric v-model="pagamento.importo" id="d_importo" :options="{
                digitGroupSeparator: '.',
                decimalCharacter: ',',
                decimalCharacterAlternative: '.',
                currencySymbol: '\u00a0€',
                currencySymbolPlacement: 's',
                roundingMethod: 'U',
                minimumValue: '0'
            }"></vue-autonumeric>

                                </div>
                            </div>

                            <div class="v-text-field__details">
                                <div class="v-messages theme--light">
                                    <div class="v-messages__wrapper"></div>
                                </div>
                            </div>

                        </div>
                    </div>




                </v-col>

            </v-row>




            <v-row>

                <v-col cols="12" md="12" style="text-align: center">



                    <button type="button" id="upload-button" class="buttonFileEditPagAlert" value="uplFile"
                        @click="openPopupUpload()">

                        <div class="containButtonFileEditPagAlert">

                            <div class="button--text">{{ testoPulsante }}</div>

                            <div style="margin-left:10px;">
                                <v-img src="@/assets/upload_file.png" max-width="20" max-height="20" />
                            </div>


                        </div>





                    </button>



                </v-col>

            </v-row>





            <v-row>

                <v-col cols="12" md="12">

                    <v-checkbox label="Invio Mail" v-model="pagamento.invia_mail" true-value="S" false-value="N"
                        :disabled="disField"></v-checkbox>

                </v-col>

            </v-row>

        </v-form>

        <div class="contaziende">

            <template>
                <div class="text-center">
                    <v-bottom-sheet v-model="sheetAziende" class="vaziendesheet">
                        <v-sheet class="text-center" height="100vh">
                            <div class="headerSheetAziende" fixed style="position:fixed;">
                                Aziende
                                <v-btn class='closeSheet align_close_sheet' icon @click="sheetAziende = !sheetAziende">
                                    <v-icon>mdi-close-circle-outline</v-icon>
                                </v-btn>

                                <v-toolbar>
                                    <v-text-field hide-details prepend-icon="mdi-magnify" single-line v-model="search">
                                    </v-text-field>

                                </v-toolbar>

                            </div>

                            <v-divider></v-divider>

                            <div class="spacer" style="min-height:72px;"></div>

                            <v-flex xs12 sm12>
                                <v-card>

                                    <v-list-item class="text-left" v-for="item in filteredAziende" :key="item.companyid"
                                        @click="manageClickAziende(item)">
                                        <v-icon aria-hidden="false">mdi-domain</v-icon>
                                        <v-list-item-title class='itemAzienda'>{{ item.fld_name }}</v-list-item-title>
                                    </v-list-item>

                                    <div style="min-height:170px;height:170px">&nbsp;</div>

                                </v-card>
                            </v-flex>
                        </v-sheet>
                    </v-bottom-sheet>
                </div>
            </template>

        </div>

        <div class="conttipologie">

            <template>
                <div class="text-center">
                    <v-bottom-sheet v-model="sheetTipologie" class="vtipologiesheet">
                        <v-sheet class="text-center" height="100vh">
                            <div class="headerSheetTipologie" fixed style="position:fixed;">
                                Tipologie
                                <v-btn class='closeSheet align_close_sheet' icon
                                    @click="sheetTipologie = !sheetTipologie">
                                    <v-icon>mdi-close-circle-outline</v-icon>
                                </v-btn>

                                <v-toolbar>
                                    <v-text-field hide-details prepend-icon="mdi-magnify" single-line
                                        v-model="searchTipologie"></v-text-field>

                                </v-toolbar>

                            </div>

                            <v-divider></v-divider>

                            <div class="spacer" style="min-height:72px;"></div>

                            <v-flex xs12 sm12>
                                <v-card>

                                    <v-list-item class="text-left" v-for="item in filteredTipologie" :key="item.id"
                                        @click="manageClickTipologie(item)">

                                        <v-img alt="Tipologia Logo" class="  " contain
                                            src="@/assets/tipologie_pagamento.png" max-width="24" max-height="24"
                                            transition="scale-transition" />

                                        <v-list-item-title class='itemTipologia'>{{ item.nome }}</v-list-item-title>

                                    </v-list-item>
                                </v-card>
                            </v-flex>
                        </v-sheet>
                    </v-bottom-sheet>
                </div>
            </template>

        </div>

        <div class="contpopupimporto">

            <template>
                <modal name="popupImporto" :clickToClose="false" :width="300" :height="180">

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <label style="font-size:15px;margin-left:8px;">Da Pagare</label>

                            <div id="d_importo_popup" style="cursor:pointer"
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute;"></label>

                                            <vue-autonumeric v-model="valoreDaPagare" :options="{
                digitGroupSeparator: '.',
                decimalCharacter: ',',
                decimalCharacterAlternative: '.',
                currencySymbol: '\u00a0€',
                currencySymbolPlacement: 's',
                roundingMethod: 'U',
                minimumValue: '0'
            }" ref="fldDaPagare" id="fldDaPagare"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-importo" contain
                                    src="@/assets/btn_cancel3_alertduedate.png" max-width="30" max-height="30"
                                    style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" />

                                <v-img alt="" class="btn-confirm-importo" contain
                                    src="@/assets/btn_confirm3_alertduedate.png" max-width="30" max-height="30"
                                    style="cursor:pointer" transition="scale-transition" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>

        <div class="contpopupimportopagato">

            <template>
                <modal name="popupImportoPagato" :clickToClose="false" :width="300" :height="340">

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <label style="font-size:15px;margin-left:8px">Da Pagare</label>

                            <div id="d_importo_popup" style="cursor:pointer"
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute;"></label>

                                            <vue-autonumeric v-model="valoreDaPagare" :disabled="true" :options="{
                digitGroupSeparator: '.',
                decimalCharacter: ',',
                decimalCharacterAlternative: '.',
                currencySymbol: '\u00a0€',
                currencySymbolPlacement: 's',
                roundingMethod: 'U',
                minimumValue: '0'
            }"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <label style="font-size:15px;margin-left:8px">Pagato</label>

                            <div id="d_importo_pagato_popup" style="cursor:pointer"
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute;"></label>

                                            <vue-autonumeric v-model="valorePagato" :options="{
                digitGroupSeparator: '.',
                decimalCharacter: ',',
                decimalCharacterAlternative: '.',
                currencySymbol: '\u00a0€',
                currencySymbolPlacement: 's',
                roundingMethod: 'U',
                minimumValue: '0'
            }"></vue-autonumeric>

                                            <v-img alt="Pagamenti Logo" class="img_copy" contain
                                                src="@/assets/copy_elements.png" max-width="24" max-height="24"
                                                transition="scale-transition" />

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-importo-pagato" contain
                                    src="@/assets/btn_cancel3_alertduedate.png" max-width="30" max-height="30"
                                    style="cursor:pointer;margin-right:10px" transition="scale-transition" />

                                <v-img alt="" class="btn-confirm-importo-pagato" contain
                                    src="@/assets/btn_confirm3_alertduedate.png" max-width="30" max-height="30"
                                    style="cursor:pointer" transition="scale-transition" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>

        <div class="contpopupimportopagatorimanente">

            <template>
                <modal name="popupImportoPagatoRimanente" :clickToClose="false" :width="300" :height="440">

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <label style="font-size:15px;margin-left:8px">Da Pagare</label>

                            <div id="d_importo_popup" style="cursor:pointer"
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute;"></label>

                                            <vue-autonumeric v-model="valoreDaPagare" :disabled="true" :options="{
                digitGroupSeparator: '.',
                decimalCharacter: ',',
                decimalCharacterAlternative: '.',
                currencySymbol: '\u00a0€',
                currencySymbolPlacement: 's',
                roundingMethod: 'U',
                minimumValue: '0'
            }"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <label style="font-size:15px;margin-left:8px">Rimanente</label>

                            <div id="d_importo_rimanente" style="cursor:pointer"
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute;"></label>

                                            <vue-autonumeric v-model="valoreRimanente" :disabled="true" :options="{
                digitGroupSeparator: '.',
                decimalCharacter: ',',
                decimalCharacterAlternative: '.',
                currencySymbol: '\u00a0€',
                currencySymbolPlacement: 's',
                roundingMethod: 'U',
                minimumValue: '0'
            }"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <label style="font-size:15px;margin-left:8px">Pagato</label>

                            <div id="d_importo_pagato_popup" style="cursor:pointer"
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute;"></label>

                                            <vue-autonumeric v-model="valorePagato" :options="{
                digitGroupSeparator: '.',
                decimalCharacter: ',',
                decimalCharacterAlternative: '.',
                currencySymbol: '\u00a0€',
                currencySymbolPlacement: 's',
                roundingMethod: 'U',
                minimumValue: '0'
            }"></vue-autonumeric>

                                            <v-img alt="Pagamenti Logo" class="img_copy_rim" contain
                                                src="@/assets/copy_elements.png" max-width="24" max-height="24"
                                                transition="scale-transition" />

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-importo-pagato-rimanente" contain
                                    src="@/assets/btn_cancel3_alertduedate.png" max-width="30" max-height="30"
                                    style="cursor:pointer;margin-right:10px" transition="scale-transition" />

                                <v-img alt="" class="btn-confirm-importo-pagato-rimanente" contain
                                    src="@/assets/btn_confirm3_alertduedate.png" max-width="30" max-height="30"
                                    style="cursor:pointer" transition="scale-transition" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>


        <div class="contpopupuploadfile">

            <template>
                <modal name="popupUploadFile" :clickToClose="false" :width="386" :height="341">

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <label style="font-size:11px;margin-left:8px;font-weight: bold;">{{ titoloPopup2 }}</label>


                        </v-col>

                    </v-row>

                    <v-row class="rowPrevFile">

                        <v-col cols="12" md="12" style="margin-left: 12px;">


                            <span class="files">
                                <input type="file" id="input-file-attach" name="logo" :accept="fileAccettati"
                                    class="dropify" data-max-width="6610" data-max-height="6200" />
                            </span>


                        </v-col>

                    </v-row>


                    <v-row style="margin-top: 0px!important;">

                        <v-col cols="12" md="12" style="margin-top: -23px!important;display:flex">

                            <label style="font-size:11px;margin-left:12px;font-weight: bold;">File:</label>

                            <div style="margin-left: 10px;font-size:11px;font-weight: bold;">{{ nomeFilePresente }}
                            </div>

                        </v-col>

                    </v-row>

                    <v-row style="margin-top: 0px!important;">

                        <v-col cols="12" md="12" style="margin-top: -7px!important;display:flex">

                            <label style="font-size:11px;margin-left:12px;font-weight: bold;">Dim:</label>

                            <div style="margin-left: 10px;font-size:11px;font-weight: bold;">{{ dimFilePresente }}</div>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-upload" contain
                                    src="@/assets/btn_cancel3_alertduedate.png" max-width="30" max-height="30"
                                    style="cursor:pointer;color:black;margin-right:10px" transition="scale-transition"
                                    @click="btnCancelUpload" />

                                <v-img alt="" class="btn-confirm-upload" contain
                                    src="@/assets/btn_confirm3_alertduedate.png" max-width="32" max-height="32"
                                    style="cursor:pointer" transition="scale-transition" @click="btnConfirmUpload" />


                            </div>

                        </v-col>

                    </v-row>



                </modal>
            </template>

        </div>



    </v-container>
</template>

<script>
import apipagamento from "../utils/pagamento/apipagamento";
import VueAutonumeric from 'vue-autonumeric/src/components/VueAutonumeric.vue';

import apiuploadfile from "../utils/uploadfile/apiuploadfile";

import {
    bus
} from "../main";

import router from ".././router";
import Vue from "vue";
import VModal from 'vue-js-modal';

export default ({
    mounted() {

        /*eslint-disable no-undef*/

        this.$root.$children[0].checkLogout();


        Vue.use(VModal, {
            dialog: true
        });

        var v_choose = window.$cookies.get("sel_tipo_soggetto_pagamento");

        if (v_choose == "P") {
            this.viewRowAzienda = false;
        }

        console.log("PARAMS: ", this.$route.params.id);

        var pointerVue = this;


        this.cur_id = this.$route.params.id;

        this.setupButtons();

        this.$root.$children[0].initDivError("#d_importo", "Importo richiesto");
        this.$root.$children[0].initDivError("#d_pagato", "");
        this.$root.$children[0].initDivError("#d_importo_rimanente", "");

        var gruppo_utente = JSON.parse(localStorage.getItem('al_info_global')).gruppo_utente;

        var elems = [];

        if (gruppo_utente != null) {
            elems = gruppo_utente.split(",");

        }

        if (elems.includes("17")) {

            this.showImpPag = false;

        }

        if (this.$route.params.id == 0) {

            this.pagamento.invia_mail = "S";

            this.disFieldRim = true;


            var id_azi_sel = window.$cookies.get("sel_filter_azienda_pagamenti");

            if (id_azi_sel != "") {
                if (id_azi_sel != "-1") {

                    this.pagamento.companyid = id_azi_sel;

                }
                else {

                    this.pagamento.companyid = 7;

                }
            }
            else {

                this.pagamento.companyid = 7;

            }

            setTimeout(() => {

                this.initInsert();

            }, 100);


        }

        if (this.$route.params.id > 0) {

            this.showImpRim = true;

            this.titolo = "Modifica Pagamento";

            setTimeout(() => {
                this.syncPagamento();
            }, 100);

        }

        // this.$root.$children[0].addClassError("#d_importo");

        this.pagamento.id_stato = "3";

        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {

                case "btn_save":

                    this.validate();

                    break;

                case "btn_back":
                    router.push({
                        path: "/pagamenti"
                    });
                    break;

                default:
                    break;
            }
        });

        $(document).ready(function () {



            $('body').on('blur', '#pagamento_descrizione', function (event) {
                console.log(event);

                pointerVue.enableAttachment();


            });

            $('body').on('blur', '#pagamento_data_scadenza', function (event) {
                console.log(event);


                setTimeout(() => {

                    pointerVue.enableAttachment();

                }, 200);



            });





            $('body').on('click', '#cont_companyid', function (event) {
                console.log(event);

                if (!pointerVue.disField) {

                    pointerVue.sheetAziende = true;

                }

            });

            $('body').on('click', '#cont_id_tipologia', function (event) {
                console.log(event);

                if (!pointerVue.disField) {

                    pointerVue.sheetTipologie = true;

                }

            });

            $('body').on('click', '.img_copy', function (event) {
                console.log(event);

                pointerVue.valorePagato = pointerVue.valoreDaPagare;

            });

            $('body').on('click', '.img_copy_rim', function (event) {
                console.log(event);

                pointerVue.valorePagato = pointerVue.valoreRimanente;

            });



            $('body').on('click', '#d_importo', function (event) {
                console.log(event);

                if (!pointerVue.disField) {

                    pointerVue.$modal.show('popupImporto');



                }

            });

            $('body').on('click', '.btn-confirm-importo', function (event) {
                console.log(event);

                pointerVue.pagamento.importo = pointerVue.valoreDaPagare;

                pointerVue.$root.$children[0].setFieldActive("#d_importo");

                pointerVue.$modal.hide('popupImporto');

                setTimeout(() => {


                    pointerVue.enableAttachment();

                }, 200);



            });

            $('body').on('click', '.btn-cancel-importo', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupImporto');

            });

            $('body').on('click', '#d_pagato', function (event) {
                console.log(event);

                if (pointerVue.$route.params.id == 0) {

                    pointerVue.$modal.show('popupImportoPagato');

                }

                if (pointerVue.$route.params.id > 0) {

                    pointerVue.$modal.show('popupImportoPagatoRimanente');

                }

            });

            $('body').on('click', '.btn-confirm-importo-pagato', function (event) {
                console.log(event);

                pointerVue.pagamento.importo_pagato = pointerVue.valorePagato;

                pointerVue.$root.$children[0].setFieldActive("#d_pagato");

                pointerVue.$modal.hide('popupImportoPagato');

            });

            $('body').on('click', '.btn-cancel-importo-pagato', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupImportoPagato');

            });

            $('body').on('click', '.btn-confirm-importo-pagato-rimanente', function (event) {
                console.log(event);

                pointerVue.pagamento.importo_pagato = pointerVue.valorePagato;

                pointerVue.$root.$children[0].setFieldActive("#d_pagato");

                pointerVue.$modal.hide('popupImportoPagatoRimanente');

            });

            $('body').on('click', '.btn-cancel-importo-pagato-rimanente', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupImportoPagatoRimanente');

            });

            setTimeout(() => {

                var oggDrop = $('.dropify').dropify({
                    messages: {
                        default: 'Drag',
                        replace: /* s */ '',
                        remove: 'Rimuovi',
                        error: 'File troppo grande'
                    }
                });

                $(".dropify-clear").html("<img src='https://app.smart-book.it/_lib/img/icons8-cestino-64.png' />")
                $(".dropify-render").append("<img>");

                //console.log(oggDrop);

                oggDrop.on('dropify.afterClear', function (event, element) {

                    console.log(event, element);

                    pointerVue.attachRemove = true;

                    pointerVue.attachPresent = false;

                });

            }, 100);

            $('#input-file-logo').on('change', function () {

                pointerVue.attachRemove = false;

                pointerVue.attachPresent = true;

            });

        });

    },

    components: {
        VueAutonumeric,
    },

    computed: {

        filteredAziende() {

            return _.orderBy(this.aziende.filter(item => {
                if (!this.search) return this.aziende;
                return (item.fld_name.toLowerCase().includes(this.search.toLowerCase()));
            }), 'headline')
        },

        filteredTipologie() {

            return _.orderBy(this.tipologie.filter(item => {
                if (!this.searchTipologie) return this.tipologie;
                return (item.nome.toLowerCase().includes(this.searchTipologie.toLowerCase()));
            }), 'headline')
        },

        solicitorsFeesDisplay: {
            get: function () {

                console.log("GET");

                if (this.pagamento.importo != undefined) {

                    return this.pagamento.importo.toFixed(2)

                } else {

                    return 0;

                }

            },
            set: function (newValue) {

                console.log("SET");

                if (newValue != "") {

                    console.log("SET VALUE; ", parseFloat(newValue).toFixed(2));

                    this.pagamento.importo = parseFloat(newValue).toFixed(2);

                }

            }
        }

    },

    data: () => ({



        // parametri funzione upload
        testoPulsante: "Upload Allegato",
        titoloPopup2: "Allega Pagamento",
        fileAccettati: ".pdf,.png,.jpg,.jpeg",
        nomeFilePresente: "",
        dimFilePresente: "",
        dimKbFilePresente: 0,
        dimMinKbFile: 100000,
        dimMaxKbFile: 5000000,
        folderServerToSave: "/documents/alertduedate/",
        folderDoxToSave: "/Products/Alertduedate/",
        removeUploadFile: 0,
        attachRemoveVisura: false,

        viewAttachment: false,


        attachPresent: false,

        viewRowAzienda: true,

        valoreDaPagare: null,
        valorePagato: null,
        valoreRimanente: null,

        disField: false,
        disFieldRim: false,

        attachRemove: false,

        showImpRim: false,

        showImpPag: true,

        importoRimanente: 0,

        currentFile: null,
        currDipendenteObj: {},
        dipendenti: [],
        currAzienda: "",
        titolo: "Aggiungi Pagamento",

        cur_id: 0,

        myValue: 0,

        sheetAziende: false,
        sheetDipendenti: false,
        searchDipendenti: "",
        search: "",

        sheetTipologie: false,
        searchTipologie: "",

        // tipologie: [],

        tipologie2: [{
            "id": 1,
            "nome": "prova"
        }, {
            "id": 2,
            "nome": "prova2"
        }],

        showPicker: false,
        showPicker2: false,

        pagamento: {
            id_stato: "",
            nome: "",
            companyid: "",
            data_scadenza: "",
            id_tipologia: "",
            importo: "",
            descrizione: ""
        },

        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) => (v && v.length <= 16) || "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) => (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [
            (v) => !!v || "Field required",

        ],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail richiesta",
            (v) => /.+@.+\..+/.test(v) || "E-mail non valida",
        ],

        nameRules: [
            (v) => !!v || "Nome richiesto",
        ],

        phoneRules: [
            (v) => !!v || "Telefono richiesto",
        ],

        nucleoRules: [
            (v) => !!v || "Nucleo richiesto",
        ],

        fiscodeRules: [
            (v) => !!v || "Codice fiscale richiesto",
        ],

        idTipologiaRules: [
            (v) => !!v || "Tipologia richiesta",
        ],

        importoRules: [
            (v) => !!v || "Campo richiesto",
            (value) => (value && value.length >= 8 && value.length <= 10) || 'minimo 8 caratteri e massimo 10',
            (value) => (value && /[A-Z]/.test(value)) || 'Almeno un carattere maiuscolo',

        ],

        dataScadenzaRules: [
            (v) => !!v || "Data scadenza richiesta",
        ],

        select: null,
        checkbox: false,

        aziende: JSON.parse(localStorage.getItem('al_info_global')).aziende,

        tipologie: JSON.parse(localStorage.getItem('al_info_global')).tipologie_pagamento,

        stati_pagamento: JSON.parse(localStorage.getItem('al_info_global')).stati_pagamento

    }),

    methods: {


        enableAttachment: function () {

            var that = this;

            var v_desc = 0;
            var v_cmp = 0;
            var v_data_scad = 0;
            var v_id_tipo = 0;
            var v_importo = 0;
            var v_result = false;

            console.log("DESCR: ", that.pagamento.descrizione);
            console.log("CMP ID: ", that.pagamento.companyid);
            console.log("DATA SCAD: ", that.pagamento.data_scadenza);
            console.log("TIPO: ", that.pagamento.id_tipologia);
            console.log("IMPORTO: ", that.pagamento.importo);

            if (that.pagamento.descrizione != "") {
                v_desc = 1;
            }

            if (that.pagamento.companyid != "") {
                v_cmp = 1;
            }

            if (that.pagamento.data_scadenza != "") {
                v_data_scad = 1;
            }

            if (that.pagamento.id_tipologia != "") {
                v_id_tipo = 1;
            }

            if (that.pagamento.importo != "") {
                v_importo = 1;
            }


            if ((v_desc == 1) && (v_cmp == 1) && (v_data_scad == 1) && (v_id_tipo == 1) && (v_importo == 1)) {

                v_result = true;

            }



            that.viewAttachment = v_result;





        },


        uploadFile: async function () {



            var that = this;

            console.log("FATT FILE: ", that.fattura);

            var id_rec = that.$route.params.id;


            var v_file = $("#input-file-attach")[0].files[0];

            that.$modal.hide('popupUploadFile');


            that.attachPresent = true;


            console.log("ID_REC: ", id_rec);
            console.log("FILE: ", v_file);
            console.log("FOLDER SERVER: ", that.folderServerToSave);
            console.log("FOLDER DOX: ", that.folderDoxToSave);

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;



            var response3 = await apiuploadfile.setFileAlertduedate(
                id_rec,
                v_file,
                that.folderServerToSave,
                that.folderDoxToSave,
                "allegato_alertduedate",
                v_token,
                that.removeUploadFile,
                that.pagamento.companyid,
                that.pagamento.id_tipologia
            ).then(async (res2) => {

                console.log(response3);

                // that.$root.$children[0].showProgress = false;
                console.log("res from setFileAlertduedate", res2);


                that.saveData();


            }).catch(err2 => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err2.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }


            );





        },



        btnCancelUpload: function () {

            this.$modal.hide('popupUploadFile');

        },


        btnConfirmUpload: function () {

            var errore = "";

            var v_file = $("#input-file-attach")[0];


            if (this.nomeFilePresente == "") {

                errore = "Specificare un'allegato";

            }

            if (v_file.files.length > 0) {



                // controllo estensione

                v_file = v_file.files[0];

                console.log("FILE 1: ", v_file);

                var v_elements = v_file.name.split('.');

                var v_ext_file = v_elements[v_elements.length - 1];

                console.log("FILE ELEMENTS: ", v_elements);
                console.log("FILE EXT: ", v_ext_file);


                var v_ele_file_accept = this.fileAccettati.split(',');

                console.log("FILE ELEMENTS ACCEPT: ", v_ele_file_accept);

                var v_find_ext = 0;

                for (var xx = 0; xx < v_ele_file_accept.length; xx++) {

                    var v_ext_file_tmp = "." + v_ext_file;

                    console.log(v_ext_file_tmp);

                    if (v_ext_file_tmp == v_ele_file_accept[xx]) {
                        v_find_ext = 1;
                    }

                }

                if (v_find_ext == 0) {
                    errore = "I formati accettati sono " + this.fileAccettati;
                }



                if (errore == "") {

                    // controllo dimensione file

                    if ((this.dimKbFilePresente < this.dimMinKbFile) || (this.dimKbFilePresente > this.dimMaxKbFile)) {


                        var strMin = (this.dimMinKbFile / 1000) + " Kb";

                        var strMax = (this.dimMaxKbFile / 1000000) + " Mb";

                        errore = "La grandezza del file deve essere compresa tra " + strMin + " e " + strMax;

                    }


                }






            }




            if (errore == "") {

                console.log("ok");

                // this.uploadFile();

                this.saveDataWithAttach();

            }
            else {

                this.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 3500
                });


            }


        },




        openPopupUpload: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.nomeFilePresente = "";
            that.dimFilePresente = "";
            that.dimKbFilePresente = 0;


            var id_rec = that.$route.params.id;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;



            var response3 = await apiuploadfile.getFile(
                id_rec,
                "allegato_alertduedate",
                that.folderServerToSave,
                v_token
            ).then((res2) => {

                console.log(response3);

                that.$root.$children[0].showProgress = false;
                console.log("res from getFile", res2);


                that.dimMinKbFile = res2.data.MinFileSize;

                that.dimMaxKbFile = res2.data.MaxFileSize;


                $(".contpopupuploadfile").show();

                this.$modal.show('popupUploadFile');


                setTimeout(() => {


                    var oggDrop = $('.dropify').dropify({
                        messages: {
                            default: 'Drag',
                            replace: /* s */ '',
                            remove: 'Rimuovi',
                            error: 'File troppo grande'
                        }
                    });

                    $(".dropify-clear").html("<img src='https://app.smart-book.it/_lib/img/icons8-cestino-64.png' />")
                    $(".dropify-render").append("<img>");

                    oggDrop.on('dropify.afterClear', function (event, element) {

                        console.log(event, element);

                        that.removeUploadFile = 1;

                        that.nomeFilePresente = "";
                        that.dimFilePresente = "";
                        that.dimKbFilePresente = 0;

                    });



                    $('#input-file-attach').on('change', function () {

                        that.removeUploadFile = 0;

                        var v_file = $("#input-file-attach")[0].files[0];

                        console.log("FILE: ", v_file);

                        that.nomeFilePresente = v_file.name;

                        var v_size = v_file.size;

                        that.dimKbFilePresente = v_size;

                        var str_size = "0 Kb";


                        if (v_size > 0) {

                            var v_division = v_size / 1000;

                            str_size = v_division.toFixed(1) + " Kb";


                        }



                        that.dimFilePresente = str_size;



                    });



                    console.log("FILE PRESENT: ", res2.data.NomeFile);

                    if (res2.data.NomeFile != null) {

                        if (res2.data.NomeFile != "") {

                            var elements = res2.data.NomeFile.split(".");

                            console.log("ELEMENTS: ", elements);

                            var v_ext = elements[elements.length - 1];

                            console.log("EXT: ", v_ext);

                            if (v_ext == "pdf") {

                                $(".rowPrevFile").find('.dropify-render').append("<i class='dropify-font-file'></i>");
                                $(".rowPrevFile").find('.dropify-render').append("<span class='dropify-extension'>" + v_ext + "</span>");
                                $(".rowPrevFile").find(".dropify-wrapper").addClass("has-preview");
                                $(".rowPrevFile").find(".dropify-preview").show("slow");

                            }
                            else {


                                var v_url = this.getUrlDomain("public/" + this.folderServerToSave) + res2.data.NomeFile;



                                //     $(".dropify-render img").attr("src", txt_image);
                                // $(".dropify-wrapper").addClass("has-preview");
                                // $(".dropify-preview").show("slow");



                                $(".rowPrevFile").find('.dropify-render img').attr("src", v_url);
                                $(".rowPrevFile").find(".dropify-wrapper").addClass("has-preview");
                                $(".rowPrevFile").find(".dropify-preview").show("slow");


                            }




                            that.nomeFilePresente = res2.data.NomeFile;

                            that.dimKbFilePresente = res2.data.FileSize;


                            var str_size = "0 Kb";

                            var v_size = res2.data.FileSize;

                            if (v_size > 0) {

                                var v_division = v_size / 1000;

                                str_size = v_division.toFixed(1) + " Kb";


                            }



                            that.dimFilePresente = str_size;

                        }


                    }








                }, 300);



            }).catch(err2 => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err2.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }


            );

        },



        formatDecimal(e) {


            if (e.target.value != "") {

                var res = parseFloat(e.target.value);

                console.log("VAL: ", res.toFixed(2));

                e.target.value = res.toFixed(2);


            }

        },

        checkImporto(e) {

            console.log("EVENT VALUE: ", e);

            if (e.target.value == "") {

                this.$root.$children[0].addClassError("#d_importo");

            } else {

                this.$root.$children[0].removeClassError("#d_importo");

            }

        },

        manageClickAziende: async function (azienda) {

            console.log("AZI SEL: ", azienda);

            this.sheetAziende = false;

            this.pagamento.companyid = azienda.companyid;

        },

        manageClickTipologie: async function (tipologia) {

            console.log("TIPO SEL: ", tipologia);

            this.sheetTipologie = false;

            this.pagamento.id_tipologia = tipologia.id;

            //this.enableAttachment();

            var v_desc = 0;
            var v_cmp = 0;
            var v_data_scad = 0;
            var v_id_tipo = 0;
            var v_importo = 0;
            var v_result = false;

            console.log("DESCR: ", this.pagamento.descrizione);
            console.log("CMP ID: ", this.pagamento.companyid);
            console.log("DATA SCAD: ", this.pagamento.data_scadenza);
            console.log("TIPO: ", this.pagamento.id_tipologia);
            console.log("IMPORTO: ", this.pagamento.importo);

            if (this.pagamento.descrizione != "") {
                v_desc = 1;
            }

            if (this.pagamento.companyid != "") {
                v_cmp = 1;
            }

            if (this.pagamento.data_scadenza != "") {
                v_data_scad = 1;
            }

            if (this.pagamento.id_tipologia != "") {
                v_id_tipo = 1;
            }

            if (this.pagamento.importo != "") {
                v_importo = 1;
            }


            if ((v_desc == 1) && (v_cmp == 1) && (v_data_scad == 1) && (v_id_tipo == 1) && (v_importo == 1)) {

                v_result = true;

            }



            this.viewAttachment = v_result;


        },


        setupButtons: async function () {

            setTimeout(() => {

                var pulsantis = [{
                    text: "Salva",
                    icon: "mdi-content-save",
                    link: "/timesheet",
                    id: "btn_save",
                    disabled: false,
                    image: "https://app.emtool.eu/public/_lib/img/save-alertduedate.png",
                    //class: "inverted",
                    title: "Salva",
                    width: 30


                },
                {
                    text: "Indietro",
                    icon: "mdi-arrow-left",
                    image: "https://app.emtool.eu/public/_lib/img/back-alertduedate.png",
                    link: "/confOrariChoice",
                    // class: "inverted",
                    id: "btn_back",
                    disabled: false,
                    title: "Indietro",
                    width: 35
                },

                ];

                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {
                    //document.querySelector('#btn_add_tms').addEventListener('click',  this.btn_add_tms_click)
                    //this.$root.$children[0].$refs.btn_add_tms.$el.addEventListener('click', this.onClick);
                }, 100);

                // this.$root.$children[0].$refs.btn_add_tms[0].$el.addEventListener('click', this.onClick);
            }, 100);

        },

        validate() {

            var that = this;

            var v_choose = window.$cookies.get("sel_tipo_soggetto_pagamento");


            var errore = "";

            console.log("PAGAMENTO: ", that.pagamento);


            if (errore == "") {

                if (that.pagamento.descrizione == "") {
                    errore = "Nome / Descrizione valore richiesto";
                }

            }



            if (errore == "") {

                if (v_choose == "A") {

                    if (that.pagamento.companyid == "") {
                        errore = "Azienda valore richiesto";
                    }

                }

            }

            if (errore == "") {

                if (that.pagamento.data_scadenza == "") {
                    errore = "Data scadenza valore richiesto";
                }

            }

            if (errore == "") {

                if (that.pagamento.id_tipologia == "") {
                    errore = "Tipologia valore richiesto";
                }

            }

            if (errore == "") {

                if (that.pagamento.importo == "") {
                    errore = "Da pagare valore richiesto";
                }

            }

            if (errore == "") {

                if (that.pagamento.importo_pagato != null) {

                    if (that.pagamento.importo_pagato != 0) {

                        if (that.pagamento.data_pagamento == null) {

                            errore = "Specificare la data di pagamento";

                        }

                    }

                }

            }

            if (that.importoRimanente == 0) {

                if (errore == "") {

                    if (that.pagamento.importo_pagato != null) {

                        if (parseFloat(that.pagamento.importo_pagato) > parseFloat(that.pagamento.importo)) {

                            errore = "Il valore del pagato non pùo essere superiore al valore da pagare";

                        }

                    }

                }

            } else {

                if (errore == "") {

                    if (that.pagamento.importo_pagato != null) {

                        if (parseFloat(that.pagamento.importo_pagato) > parseFloat(that.importoRimanente)) {

                            var v_rim = parseFloat(that.importoRimanente);

                            errore = "Il valore del pagato non pùo essere superiore al valore rimanente da pagare di € " + v_rim.toFixed(2);

                        }

                    }

                }

            }

            if (that.disField == true) {

                if (that.pagamento.data_pagamento == null) {

                    errore = "Specificare la data di pagamento";

                }

            }

            if (errore == "") {

                if (that.disField == true) {

                    if (that.pagamento.importo_pagato == null) {

                        errore = "Specificare l'importo pagato";

                    } else {
                        if (that.pagamento.importo_pagato == 0) {

                            errore = "Specificare l'importo pagato";

                        }
                    }

                }

            }

        
            if (errore == "") {

                if (that.attachPresent == false) {

                    errore = "Allegato richiesto";

                }


            }

        




            if (errore == "") {


                console.log("ok");

                that.saveData();


            }
            else {


                that.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 2000
                });


            }





        },



        getUrlDomain: function (nome_metodo) {

            let result = "";

            if ($(location).attr('hostname') == "localhost") {
                result = $(location).attr('protocol') + "//emtool.local/";
            }

            if ($(location).attr('hostname') != "localhost") {

                result = $(location).attr('protocol') + "//" + $(location).attr('hostname') + "/";

            }

            return result + "" + nome_metodo;

        },


        saveDataWithAttach: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");
            var v_choose2 = window.$cookies.get("sel_tipo_soggetto_pagamento");

            var v_file = $("#input-file-attach")[0].files[0];

            that.$modal.hide('popupUploadFile');


            var id_rec = that.$route.params.id;

            console.log("TOKEN ", v_token);
            console.log("PAGAMENTO ", that.pagamento);
            console.log("ID_REC ", id_rec);

            // console.log("AZIENDA ", );

            if (v_choose2 == "P") // privato
            {
                that.pagamento.companyid = null;
            }

            // console.log("CUR_FILE: ", $('#input-file-logo')[0].files[0]);

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apipagamento.setPagamentoWithAttach(
                that.pagamento,
                that.$route.params.id,
                v_token,
                v_file,
                false,
                v_choose2
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from setPagamento", res);

                that.$swal({
                    icon: "success",
                    text: "Dati salvati correttamente",
                    showConfirmButton: false,
                    timer: 2000
                });

                setTimeout(() => {

                    that.dialogMsg = false;
                    that.setupButtons();

                    router.push({
                        path: "/pagamenti"
                    });

                }, 200);

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );

        },





        saveData: async function () {

            var v_token = window.$cookies.get("token");
            var v_choose2 = window.$cookies.get("sel_tipo_soggetto_pagamento");

            var that = this;

            var id_rec = that.$route.params.id;

            console.log("TOKEN ", v_token);
            console.log("PAGAMENTO ", that.pagamento);
            console.log("ID_REC ", id_rec);

            // console.log("AZIENDA ", );

            if (v_choose2 == "P") // privato
            {
                that.pagamento.companyid = null;
            }

            // console.log("CUR_FILE: ", $('#input-file-logo')[0].files[0]);

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apipagamento.setPagamento(
                that.pagamento,
                that.$route.params.id,
                v_token,
                undefined,
                false,
                v_choose2
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from setPagamento", res);

                that.$swal({
                    icon: "success",
                    text: "Dati salvati correttamente",
                    showConfirmButton: false,
                    timer: 2000
                });

                setTimeout(() => {

                    that.dialogMsg = false;
                    that.setupButtons();

                    router.push({
                        path: "/pagamenti"
                    });

                }, 200);

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );

        },


        initInsert: async function () {

            var v_token = window.$cookies.get("token");

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apipagamento.initInsert(
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from initInsert", res);


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },




        syncPagamento: async function () {

            var v_token = window.$cookies.get("token");

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apipagamento.getPagamento(
                that.$route.params.id, v_token

            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getPagamento", res);

                try {

                    this.pagamento = res.data.Result;

                    this.importoRimanente = res.data.Result.importo_rimanente;

                    if (res.data.Result.is_child_pag == "N") // pagamento master
                    {

                        this.pagamento.importo_pagato = null;

                        var today = new Date();

                        var sDate = today.getFullYear() + "-" + (today.getMonth() + 1).toString().padStart(2, 0) + "-" + today.getDate().toString().padStart(2, 0);

                        console.log("DATE: ", sDate);

                        this.pagamento.data_pagamento = sDate; // default data corrente



                        if (res.data.Result.allegato_salvato != null) {


                            that.attachPresent = true;

                        }



                    }

                    if (res.data.Result.is_child_pag == "S") // pagamento child
                    {

                        this.disField = true;

                    }

                    if (res.data.Result.importo != null) {

                        this.$root.$children[0].setFieldActive("#d_importo");

                    }

                    if (res.data.Result.importo_pagato != null) {

                        this.$root.$children[0].setFieldActive("#d_pagato");

                    }

                    if (res.data.Result.importo_rimanente != null) {

                        this.$root.$children[0].setFieldActive("#d_importo_rimanente");

                    }

                    this.valoreDaPagare = res.data.Result.importo;

                    this.valoreRimanente = res.data.Result.importo_rimanente;

                    this.disFieldRim = true;

                    $("#item_id_tipologia").addClass("item-has-value");


                    setTimeout(() => {

                        that.enableAttachment();

                    }, 200);



                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },

        loadTipologie: async function () {

            var v_token = window.$cookies.get("token");

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apipagamento.getTipologie(
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getTipologie", res);

                try {

                    this.tipologie = res.data.Result;

                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        }

    }

})
</script>

<style>
.editPagamentoAlert .rowPrevFile .dropify-wrapper {
    height: 187px !important;
    width: 363px !important;
    margin-bottom: 20px;
    margin-left: 1px;
}



.buttonFileEditPagAlert {
    border: 1px solid black;
    padding: 10px;
    width: 162px;
}

.containButtonFileEditPagAlert {
    display: flex;
    justify-items: center;
    align-items: center;
    justify-content: center;
}






.w-container {
    width: 500px;
    padding: 0px;
    z-index: 3;
}

.v-form {
    margin-top: 41px;
}

.mainTitle {
    padding: 20px !important;
    position: fixed;
    min-width: 500px;
    z-index: 1;
    top: 67px;
    border: 1px solid black;
}

.dropify-clear {
    border: 0px !important;
    margin-top: -7px !important;
    margin-right: -7px !important;
}

.align_close_sheet {
    top: 23px !important
}

@media screen and (max-width: 768px) {

    .align_close_sheet {
        top: 18px !important
    }

    .v-form {

        padding-top: 45px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .w-container {
        width: 100vw;
        padding: initial;
        padding: inherit;
    }

    .mainTitle {
        padding: 20px !important;
        position: fixed;
        min-width: 100vw;
        left: 0;
        z-index: 1;
        top: 57px;
    }

    .dropify-clear {
        border: 0px !important;
        margin-bottom: -26px !important;
    }

}

.headerSheetAziende {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.headerSheetTipologie {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.closeSheet {
    position: absolute;
    right: 10px;
    top: 26px;
    z-index: 2;
}

.itemAzienda {
    margin-left: 15px;
}

.itemTipologia {
    margin-left: 15px;
}

.v-application .text-center {

    overflow: auto;
}

.dropify-clear img {
    /* display: none; */
    width: 20px !important;
}

.dropify-wrapper {
    height: 236px !important;
    width: 465px !important;
    margin-bottom: 20px;
    margin-left: 10px;
}

.img_copy {
    cursor: pointer;
}

.img_copy_rim {
    cursor: pointer;
}
</style>
